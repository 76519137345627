import request from '@/utils/request'

export function listPage(params) {
    return request({
        method:'get',
        url:'/api/warehouseAdjustNote/listPage',
        params
    })
}

// 查询初始化
export function pageInit() {
    return request({
        method:'get',
        url:'/api/warehouseAdjustNote/pageInit',
    });
}
export function addInit() {
    return request({
        method:'get',
        url:'/api/warehouseAdjustNote/addInit',
    });
}

export function getProductListPage(params) {
    return request({
      url: "/api/warehouseDetail/listPage",
      params,
      method: "get" 
    })
} 
export function warehouseAdjustNote(data) {
    return request({
      url: "/api/warehouseAdjustNote",
      data,
      method: "post" 
    })
} 
export function detail(id) {
    return request({
      url: `/api/warehouseAdjustNote/${id}`,
      method: "get" 
    })
} 
export function updateInit(id) {
    return request({
      url: `/api/warehouseAdjustNote/updateInit/${id}`,
      method: "get" 
    })
} 
export function update(data) {
    return request({
      url: `/api/warehouseAdjustNote`,
      method: "put",
      data
    })
} 
export function batchUpdate(data) {
    return request({
      url: `/api/warehouseAdjustNote/batchUpdate`,
      method: "post",
      data
    })
} 
export function batchPrintAdjust(data) {
    return request({
      url: `/api/warehouseAdjustNote/batchPrintAdjust`,
      method: "post",
      data
    })
} 
export function updateTips(data) {
    return request({
      url: `/api/warehouseAdjustNote/updateTips`,
      method: "post",
      data
    })
} 

// 操作记录
export function operationLog(params) {
    return request({
        url:`/api/warehouseOperateRecord/list`,
        method:'get',
        params
    })
}
<template>
    <div class="Box">
        <div>
            <el-tabs style="margin-bottom:8px" type="card" v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部" name="all"></el-tab-pane>
                <el-tab-pane 
                :label="item.value" 
                :name="item.id"
                v-for="item in adjustStatusList"
                :key="item.id">
                <span slot="label">{{item.value}} <span v-if="item.size">({{item.size}})</span></span>
                </el-tab-pane>
                <!-- <el-tab-pane label="待调整" name="toAdjust"></el-tab-pane>
                <el-tab-pane label="已完成" name="finished"></el-tab-pane> -->
            </el-tabs>
            <el-select clearable @clear="find" @change="find" v-model="pageInfo.storageId" style="width:120px;margin-right:10px" placeholder="全部仓库">
                <el-option
                    v-for="item in storageList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select clearable @clear="find" @change="find" v-model="pageInfo.type" style="width:120px;margin-right:10px" placeholder="全部类型">
                <el-option
                    v-for="item in adjustTypeList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="pageInfo.timeType" style="width:120px;margin-right:3px" placeholder="时间类型">
                <el-option
                    v-for="item in adjustSearchTimeTypeList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-date-picker
            style="margin-right:10px"
            v-model="time"
            @change="getTime"
            type="daterange"
            range-separator="至"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
            </el-date-picker>
            <el-input  clearable @clear="find" v-model="pageInfo.keyword" placeholder="搜索内容" style="width:300px;margin-right:10px">
                <el-select v-model="pageInfo.searchType" slot="prepend" style="width:100px">
                    <el-option
                        v-for="item in adjustSearchTypeList"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-input> 
            <el-button type="primary" @click="find">查询</el-button><br><br>
            <el-button v-if="activeName=='all'" type="primary" @click="addInit('添加调整单','add')">添加调整单</el-button>
            <el-dropdown v-if="activeName=='all'" trigger="click">
                <el-button style="margin:0 10px 1px 10px">更多批量操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="batchUpdate('submit','请选择待提交/已驳回的调整单')">批量提交</el-dropdown-item>
                    <el-dropdown-item @click.native="batchUpdate('submit','请选择待提交/已驳回的调整单')">批量提交并调整</el-dropdown-item>
                    <el-dropdown-item @click.native="batchUpdate('adjust','请选择待调整的调整单')">批量确认调整</el-dropdown-item>
                    <el-dropdown-item @click.native="batchUpdate('delete','请选择待提交/待审批/待调整/已驳回的调整单')">批量删除</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <template v-if="activeName=='toSubmit'">
                <el-button type="primary">批量提交</el-button>
                <el-button type="primary">批量提交并调整</el-button>
                <el-button>批量删除</el-button>
            </template>
            <template v-if="activeName=='toAdjust'">
                <el-button type="primary">批量确认调整</el-button>
                <el-button>批量删除</el-button>
            </template>
            <el-button @click="batchPrintAdjusts">批量打印调整单</el-button>
        </div>
        <div class="content">
            <el-table
                v-loading='loading'
                height="100%"
                class="outTable"
                :span-method="arraySpanMethod"
                @selection-change="select"
                :data="tableData"
                style="width: 100%;border-top:1px solid #EBEEF5"
                row-key="childId"
                default-expand-all
                :cell-style="{ fontSize: '13px', padding: '7px 0',textAlign:'center' }"
                :tree-props="{children: 'itemList'}"
                :header-cell-style="{
                    fontSize: '13px',
                    padding: '9px 0',
                    marginTop: '10px',
                    textAlign:'center'
                }">
                <el-table-column
                    type="selection"
                    width="width">
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="图片"
                    width="width">
                    <template slot-scope="scope">
                        <div v-if="scope.row.itemList!=undefined" class="tableMenu">
                            <div>
                                <CopyButton :content='scope.row.adNo'>
                                    <el-button slot="copy" type="text">{{scope.row.adNo}}</el-button>
                                </CopyButton>
                            </div>
                            <div>
                                <span>入库仓库：</span>
                                <span>{{scope.row.storageName}}</span>
                            </div>
                            <div>
                                <span>类型：</span>
                                <span>{{scope.row.typeName}}</span>
                            </div>
                            <div>
                                <span>创建人/创建时间：</span>
                                <span>{{scope.row.createByName}}/{{scope.row.createTime}}</span>
                            </div>
                            <div>
                                <span>备注：</span>
                                <span>{{scope.row.tips}}</span>
                                <el-popover
                                placement="bottom"
                                width="250"
                                ref="tip"
                                @hide='hiddenTip(scope.row.id)'
                                trigger="click">
                                    <div>
                                        <el-input type="textarea" rows="4" v-model="tipsContent" placeholder=""></el-input>
                                    </div>
                                    <span @click="tipsContent=scope.row.tips" class="el-icon-edit" slot="reference" style="color:#409EFF;margin-left:10px;cursor:pointer"></span>
                                </el-popover>
                            </div>
                            <div style="flex:1;text-align:right;padding-right:35px;display:flex;justify-content:flex-end;align-items:center">
                                <span v-show="scope.row.status=='toAdjust'" class="status orange">待调整</span>
                                <span v-show="scope.row.status=='toSubmit'" class="status green">待提交</span>
                                <span v-show="scope.row.status=='finished'" class="status">已完成</span>

                                <el-button type="text" style="margin-top:-1.5px;margin-left:10px" @click="getDetail(scope.row.id,'detail')">详情</el-button>
                                <el-dropdown  v-if="scope.row.status!='finished'" trigger="click" placement='bottom'>
                                    <el-button type="text" style="margin-right:10px;margin-left:10px">操作<i class="el-icon-arrow-down"></i></el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <template v-if="scope.row.status=='toSubmit'">
                                            <el-dropdown-item @click.native="batchUpdate('submit',scope.row.adNo,scope.row.id)">提交</el-dropdown-item>
                                            <el-dropdown-item @click.native="batchUpdate('submitAndAdjust',scope.row.adNo,scope.row.id)">提交并调整</el-dropdown-item>
                                            <el-dropdown-item @click.native="getUpdateInit(scope.row.id,'update')">编辑</el-dropdown-item>
                                        </template>
                                        
                                        <el-dropdown-item v-if="scope.row.status=='toAdjust'" @click.native="batchUpdate('adjust',scope.row.adNo,scope.row.id)">确认调整</el-dropdown-item>

                                        <el-dropdown-item v-if="scope.row.status=='toAdjust'||scope.row.status=='toSubmit'" @click.native="batchUpdate('delete',scope.row.adNo,scope.row.id)">删除</el-dropdown-item>
                                        <el-dropdown-item v-if="scope.row.status=='toAdjust'||scope.row.status=='finished'" @click.native="batchUpdate('adjust',scope.row.adNo,scope.row.id)">打印</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <el-button style="margin-right:20px" type="text" v-if="scope.row.status=='finished'" @click.native="batchPrintAdjust([scope.row.id])">打印</el-button>

                            </div>
                        </div>
                        <div v-else style="">
                            <Picture style="margin:auto" :url='scope.row.imageUrl' :width='40'></Picture>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="品名/SKU"
                    width="width">
                    <template v-if="scope.row.itemList==undefined" slot-scope="scope">
                        <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                        <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="FNSKU/店铺"
                    width="width">
                </el-table-column>
                <el-table-column
                    prop="goodQuantity"
                    label="可用调整量"
                    width="width">
                </el-table-column>
                <el-table-column
                    prop="badQuantity"
                    label="次品调整量"
                    width="width">
                </el-table-column>
                <el-table-column
                header-align="center"
                    prop="prop"
                    label="操作"
                    width="width">
                </el-table-column>
            </el-table>
        </div>
        <div style="text-align:center;border-top:1px solid #E4E7ED;padding:10px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
        <el-drawer
            :visible.sync="addOderBox"
            width="1000px"
            size='89%'
            direction="btt"
            :before-close="addOrderBoxClose">
            <div slot="title" style="display:flex;align-items:center">
                <h3 style="margin-right:10px">{{title}}</h3>
                <span v-show="detailInfo.status=='toAdjust'" class="status orange">待调整</span>
                <span v-show="detailInfo.status=='toSubmit'" class="status green">待提交</span>
                <span v-show="detailInfo.status=='finished'" class="status">已完成</span>
            </div>
            <div style="padding:20px 20px 80px 20px">
                <el-steps v-if="title!='添加调整单'" :active="stepNumber" style="width:90%;margin-bottom:20px">
                    <el-step title="创建">
                        <span v-if="stepNumber>=1" slot="description">{{detailInfo.createByName+' '+detailInfo.createTime}}</span>
                    </el-step>
                    <el-step title="提交">
                        <span v-if="stepNumber>=2" slot="description">{{detailInfo.submitByName+' '+detailInfo.submitTime}}</span>
                    </el-step>
                    <el-step title="调整">
                        <span v-if="stepNumber>=4" slot="description">{{detailInfo.finishByName+' '+detailInfo.finishTime}}</span>
                    </el-step>
                    <el-step title="完成">
                        <span v-if="stepNumber>=4" slot="description">{{detailInfo.finishByName+' '+detailInfo.finishTime}}</span>
                    </el-step>
                </el-steps>
                <el-form ref="addform" :inline='true' :model="addform" label-width="100px">
                    <el-form-item 
                    label="仓库:"  
                    prop='storageId'
                    :rules="[{required:true,message:'请选择仓库',trigger:'change'}]">
                        <el-select :disabled="this.boxType!='add'" v-model="addform.storageId" placeholder="请选择仓库">
                            <el-option
                                v-for="item in storageList"
                                :key="item.id"
                                :label="item.value"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="调整类型:" prop="type"
                    :rules="[{required:true,message:'请选择类型',trigger:'change'}]">
                        <el-select :disabled="this.boxType!='add'" v-model="addform.type" placeholder="请选择类型">
                            <el-option
                                v-for="item in adjustTypeList"
                                :key="item.id"
                                :label="item.value"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item><br>
                    <el-form-item label="备注:" prop="tips">
                        <el-input v-model="addform.tips" type="textarea" rows="3" cols='100' placeholder="备注"></el-input>
                    </el-form-item>
                </el-form><br><br>
                <template v-if="this.boxType!='detail'">
                    <el-button @click="pushProduct">添加产品</el-button>
                    <el-button @click="uploadProduct">导入产品</el-button><br><br>
                </template>
                <el-tabs v-model="AddBoxActiveName" type="card">
                    <el-tab-pane :label="title=='添加调整单'?'已添加商品列表':'调整产品'" name="first">
                        <el-table
                        :data="addGoodsList"
                        :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                        :header-cell-style="{
                            fontSize: '13px',
                            padding: '9px 0',
                            marginTop: '10px',
                            background: 'white',
                        }">
                            <el-table-column
                            label='图片'
                            prop=''>
                            <template slot-scope="scope">
                                <Picture :url='scope.row.imageUrl' :width='40'></Picture>
                            </template>
                            </el-table-column>
                            <el-table-column
                            label='品名/SKU'
                            prop=''>
                            <template slot-scope="scope">
                                <p :title="scope.row.productName||scope.row.title" class="oneline">{{scope.row.title}}</p>
                                <p :title="scope.row.commoditySku||scope.row.sku" class="oneline">{{scope.row.commoditySku||scope.row.sku}}</p>
                            </template>
                            </el-table-column>
                            <el-table-column
                            label='原FNSKU/店铺'
                            prop=''>
                            </el-table-column>
                            <template v-if="boxType=='add'||boxType=='update'">
                                <el-table-column
                                label='可用量'
                                prop="leftGoodQuantity">
                                </el-table-column>
                                <el-table-column
                                label='可用量调整'
                                prop='' 
                                width="200">
                                <template slot-scope="scope">
                                    <el-input 
                                    style="margin-top:4px" 
                                    v-model="scope.row.adjustGood" 
                                    placeholder="可用调整量"
                                    @input="scope.row.adjustGood=scope.row.adjustGoodType=='add'?scope.row.adjustGood:(scope.row.adjustGood>scope.row.leftGoodQuantity?scope.row.leftGoodQuantity:scope.row.adjustGood)"
                                    onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')">
                                        <el-select style="width:80px" slot="prepend" v-model="scope.row.adjustGoodType" @change="scope.row.adjustGood=scope.row.adjustGoodType=='add'?scope.row.adjustGood:(scope.row.adjustGood>scope.row.adjustGood?scope.row.adjustGood:scope.row.adjustGood)">
                                            <el-option label="增加" value="add"></el-option>
                                            <el-option label="减少" value="del"></el-option>
                                        </el-select>
                                    </el-input>
                                </template>
                                </el-table-column>
                                <el-table-column
                                label='次品量'
                                prop="leftBadQuantity">
                                </el-table-column>
                                <el-table-column
                                label='次品量调整'
                                prop='' 
                                width="200">
                                <template slot-scope="scope">
                                    <el-input 
                                    style="margin-top:4px" 
                                    v-model="scope.row.adjustBad" 
                                    placeholder="次品调整量"
                                    onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')">
                                            <el-select style="width:80px" slot="prepend" v-model="scope.row.adjustBadType" @change="scope.row.adjustBad=scope.row.adjustBadType=='add'?scope.row.adjustBad:(scope.row.adjustBad>scope.row.leftBadQuantity?scope.row.leftBadQuantity:scope.row.adjustBad)">
                                            <el-option label="增加" value="add"></el-option>
                                            <el-option label="减少" value="del"></el-option>
                                        </el-select>
                                    </el-input>
                                </template>
                                </el-table-column>
                            </template>
                            <template v-if="boxType=='detail'">
                                <el-table-column
                                label='可用量调整'
                                prop='goodQuantity' 
                                width="200">
                                </el-table-column>
                                <el-table-column
                                label='次品量调整'
                                prop='badQuantity' 
                                width="200">
                                </el-table-column>
                            </template>
                            <el-table-column
                            v-if="boxType=='add'||boxType=='update'"
                            label='操作'
                            prop=''>
                            <template slot-scope="scope">
                                <el-button type="text" @click="addGoodsList.splice(scope.$index,1)">移除</el-button>
                            </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="操作记录" name="second" v-if="title!='添加调整单'&&boxType!='update'">
                        <el-table
                            :data='operationLogList'
                            :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                            :header-cell-style="{
                                fontSize: '13px',
                                padding: '9px 0',
                                marginTop: '10px',
                                background: 'white',
                            }">
                                <el-table-column
                                label='操作时间'
                                width="180"
                                prop='createTime'>
                                </el-table-column>
                                <el-table-column
                                label='操作人'
                                width="200"
                                prop='createByName'>
                                </el-table-column>
                                <el-table-column
                                label='操作类型'
                                width="100"
                                prop='operateTypeName'>
                                </el-table-column>
                                <el-table-column
                                label='操作详情'
                                prop='details'>
                                </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div style="position:absolute;bottom:0;padding:10px 20px 30px 20px;border-top:1px solid #DCDFE6;width:100%;text-align:center;background:white;z-index:10">
                <el-button @click="addOrderBoxClose">关闭</el-button>
                <template v-if="this.boxType!='detail'">
                    <el-button @click="warehouseAdjustNote('save')">暂存</el-button>
                    <el-button @click="warehouseAdjustNote('submit')" type="primary">提交</el-button>
                    <el-button type="primary" @click="warehouseAdjustNote('submitAndAdjust')">提交并调整</el-button>
                </template>
            </div>
        </el-drawer>
        <!-- 添加商品 -->
        <el-dialog
            title="添加商品"
            :visible.sync="selectGoodsBox"
            width="900px"
            append-to-body
            :before-close="dialogBeforeClose">
            <div v-loading='dialogLoading'>
                <el-input v-model="goodsListInfo.keyword" placeholder="SKU\品名" style="width:250px;margin-right:10px"></el-input>
                <el-button type="primary" @click="getProductListPage('find')">查询</el-button>
                <el-table
                :data='goodsList'
                height='400'
                ref="goodsTable"
                @select-all='selectAll'
                @select="selectGoods"
                style="width: 100%;margin-top:10px"
                :cell-style="{ fontSize: '13px', padding: '7px 0'}"
                :header-cell-style="{
                    fontSize: '13px',
                    padding: '9px 0',
                    marginTop: '10px',
                    background: '#EBEEF5',
                }">
                    <el-table-column
                        type="selection"
                        width="55px" >
                    </el-table-column>
                    
                    <el-table-column
                        prop="prop"
                        label="图片" >
                        <template slot-scope="scope">
                            <Picture :url='scope.row.imageUrl' width='40'></Picture>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="品名/SKU" >
                        <template slot-scope="scope">
                            <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                            <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                        </template>
                    </el-table-column>
                    
                    <el-table-column
                        prop="goodQuantity"
                        label="可用量" >
                    </el-table-column>
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="badQuantity"
                        label="次品量" >
                    </el-table-column>
                </el-table>
                <div style="text-align:center;padding-top:10px">
                    <el-pagination
                    @size-change="goodsSizeChange"
                    @current-change="goodsCurrentChange"
                    :current-page="goodsListInfo.current"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="goodsListInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="goodsListInfo.total">
                    </el-pagination>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="selectGoodsBox = false">取 消</el-button>
                <el-button type="primary" @click="addProduct">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="导入商品"
            :visible.sync="uploadBox"
            width="700px"
            :destroy-on-close='true'
            :before-close="uploadBoxClose">
            <div>
                <el-upload
                class="upload-demo"
                :action="action"
                :headers="{token:mytoken.token,storageId:addform.storageId}"
                :on-success="uploadSuccess"
                multiple
                :on-exceed="handleExceed"
                :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
                <el-button @click.native="downTemplate" slot="tip" type="text" style="margin-left:15px" class="el-upload__tip">下载模板</el-button>
                </el-upload>
                <ul style="line-height:25px;margin-top:20px">
                    <li>支持导入格式为xlsx的文件(手动修改文件后缀无效)</li>
                    <li>模版中的表头不可更改，不可删除</li>
                    <li>数据记录不要超过500条</li>
                    <li>数量调整，SKU+FNSKU重复，以第一条为准</li>
                    <li>换标调整，SKU+原FNSKU+调整FNSKU重复，以第一条为准</li>
                </ul><br><br>
                <el-table
                    :data="faildList"
                    v-if="faildList.length>0"
                    style="width: 100%"
                    :cell-style="{ fontSize: '13px', padding: '7px 0',textAlign:'left' }"
                    :header-cell-style="{
                        fontSize: '13px',
                        padding: '9px 0',
                        marginTop: '10px',
                        background: '#EBEEF5',
                        textAlign:'center'
                    }">
                    <el-table-column
                        prop="value"
                        label="失败原因"
                        width="width">
                    </el-table-column>
                </el-table>
            </div>
            <div slot="footer">
                <el-button @click="uploadBoxClose">取 消</el-button>
                <el-button type="primary" @click="uploadBoxClose">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    pageInit,
    listPage,
    addInit,
    getProductListPage,
    warehouseAdjustNote,
    updateInit,
    batchUpdate,
    update,
    operationLog,
    updateTips,
    batchPrintAdjust,
    detail
} from '@/api/InventoryManage/adjustTheSingle.js'
export default {
    data(){
        var token = window.sessionStorage.getItem('token')

        return {
            tableData:[],
            total:0,
            title:'',

            mytoken:{token:token},

            loading:false,
            addOderBox:false,
            dialogLoading:false,
            adjustSearchTimeTypeList:[],
            adjustSearchTypeList:[],
            adjustStatusList:[],
            adjustTypeList:[],
            storageList:[],

            tableSelectList:[],

            activeName:'all',

            time:[],
            pageInfo:{
                current:1,
                pageSize:10,
                keyword:'',
                searchType:'ad_no',
                endTime:'',
                beginTime:'',
                timeType:'create_time',
                type:'',
                storageId:'',
                status:'',
            },

            // 添加
            AddBoxActiveName:'first',
            selectGoodsBox:false,
            addform:{
                storageId:'',
                tips:'',
                type:''
            },
            goodsListInfo:{
                pageSize:10,
                current:1,
                keyword:'',
                total:0
            },
            goodsList:[],
            selectedList:[],
            addGoodsList:[],
            detailInfo:{},

            tipsContent:'',

            operationLogList:[],
            uploadBox:false,
            fileList:[],
            faildList:[]
        };
    },
    created(){
        this.getPageInit()
        this.getListPage()
    },
    computed:{
        stepNumber(){
            switch(this.detailInfo.status){
                case 'toSubmit':
                return 1
                case 'toAdjust':
                return 2;
                case 'finished':
                return 4
                case 'revoked':
                return 4
            }
        },
        action(){
            return process.env.VUE_APP_API_URL+ '/api/warehouseAdjustNote/importProductWhenAddOrUpdate'
        }
    },
    methods:{
        uploadSuccess(res,file,fileList){
            if(res.code==200){
                
                const arr = res.data.map(item=>{
                    item.leftGoodQuantity=item.goodQuantity
                    item.leftBadQuantity=item.badQuantity

                    return {
                        ...item,
                        adjustGoodType:item.exportGoodQuantity>0?'add':'del',
                        adjustBadType:item.exportBadQuantity>0?'add':'del',
                        adjustGood:item.exportGoodQuantity>=0?item.exportGoodQuantity:-item.exportGoodQuantity,
                        adjustBad:item.exportBadQuantity>=0?item.exportBadQuantity:-item.exportBadQuantity
                    };
                })

                if(this.addGoodsList.length==0){
                    this.addGoodsList=arr
                    this.uploadBoxClose()
                    this.$message.success(res.message)
                    return
                }

                arr.forEach(item=>{
                    this.addGoodsList.forEach((i,ind)=>{
                        if(item.productId==i.productId){
                            this.addGoodsList.splice(ind,1,item)
                        }
                    })
                    const list = this.addGoodsList.map(item=>{
                        return item.productId
                    })
                    if(!list.includes(item.productId)){
                        this.addGoodsList.push(item)
                    }
                })

                this.uploadBoxClose()
                this.$message.success(res.message)
            }else{
                this.$message.error(res.message)
                this.faildList=res.data.map((item,index)=>{
                    return {
                        id:index,
                        value:item
                    }
                })
            }
        },
        uploadProduct(){
            this.$refs.addform.validate(valid=>{
                if(!valid) return 
                this.uploadBox=true
            })
        },
        uploadBoxClose(){
            this.uploadBox=false
            this.faildList=[]
            this.fileList=[]
        },
        downTemplate(){
            window.location.href='导入调整单商品.xlsx'
        },
        operationLog(id,type){
            operationLog({objectId:id,operateObject:type}).then(res=>{
                this.operationLogList=res.data.data
            })
        },
        hiddenTip(id){
            this.$confirm('确认修改备注, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading=true
                updateTips({id:id,tips:this.tipsContent}).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.getListPage()
                    }else {
                        this.$message.error(res.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        getTime(val){
            console.log(val);
            if(val!=null){
                this.pageInfo.beginTime=val[0]
                this.pageInfo.endTime=val[1]
                this.getListPage()
            }else{
                this.pageInfo.beginTime=''
                this.pageInfo.endTime=''
                this.getListPage()
            }
        },
        batchPrintAdjusts(){
            if(this.tableSelectList.length==0) return this.$message.error('请选择具体内容')
            this.batchPrintAdjust(this.tableSelectList.map(item=>item.id))
        },
        batchPrintAdjust(ids){
            this.loading=true
            batchPrintAdjust({ids:ids}).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message,'等待跳转')
                    window.open(res.data.data)
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            if (row.itemList!=undefined) {
                if (columnIndex == 1) {
                    return [1, 6];
                } else {
                    return [1, 1];
                }
            }else{
                [0,1]
            }
        },
        select(val){
            console.log(val);
            this.tableSelectList=val
            console.log(this.tableSelectList);
        },
        batchUpdate(status,info,id){
            let str = ''
            let ids = []
            if(id){
                ids=[id]
                if(status=='delete'){
                    str=`确定删除 ${info} ?
                    删除后不可恢复`
                }else if(status=='submit'){
                    str=`确定提交 ${info} ?
                    提交后不可再次编辑`
                }else if(status=='submitAndAdjust'){
                    str=`确定提交并调整 ${info} ?
                    提交后，调整单状态变更为【已完成】`
                }else if(status=='adjust'){
                    str=`确定调整 ${info} ?
                    调整后，调整单状态变更为【已完成】`
                }
            }else if(id==undefined) {
                if(this.tableSelectList.length==0) return this.$message.error('请选择内容')
                ids = this.tableSelectList.map(item=>item.id)
                let arr = []
                if(status=='submit'||status=='submitAndAdjust'){
                    arr = this.tableSelectList.filter(item=>item.status=='toSubmit')
                    if(status=='submit') str=`共${arr.length}个调整单，确认提交？提交后不可再次编辑`
                    if(status=='submitAndAdjust') str=`共${arr.length}个调整单，确定提交并调整？调整后调整单状态变更为【已完成】` 
                }else if(status=='delete'){
                    arr = this.tableSelectList.filter(item=>item.status=='toSubmit'||item.status=='toAdjust')
                    str = `共${arr.length}个调整单，确认删除？删除后不可恢复`
                }else if (status=='adjust') {
                    arr = this.tableSelectList.filter(item=>item.status=='toAdjust')
                    str = `共${arr.length}个调整单，确认调整？调整后，调整单状态变更为已完成`
                }
                if(arr.length!=this.tableSelectList.length) return this.$message.error(info)
            }

            this.$confirm(`${str}, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading=true
                batchUpdate({ids:ids,operateType:status}).then(res=>{
                    this.loading=false
                    console.log(res);
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        if(this.addOderBox) this.addOrderBoxClose()
                        this.getListPage()
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        handleClick(){
            this.getListPage()
        },
        getUpdateInit(id,boxType){
            this.boxType=boxType
            updateInit(id).then(res=>{
                if(res.data.code==200){
                    for(var key in this.addform){
                        this.addform[key]=res.data.data[key]
                    }
                    for(var key in res.data.data){
                        this.$set(this.detailInfo,key,res.data.data[key])
                    }
                    this.addGoodsList=res.data.data.itemList.map(item=>{
                        if(item.leftGoodQuantity==0) item.goodQuantity=0
                        if(item.leftBadQuantity==0) item.BadQuantity=0
                        return {
                            ...item,
                            adjustGoodType:item.goodQuantity>0?'add':'del',
                            adjustBadType:item.badQuantity>0?'add':'del',
                            adjustGood:item.goodQuantity>=0?item.goodQuantity:-item.goodQuantity,
                            adjustBad:item.badQuantity>=0?item.badQuantity:-item.badQuantity
                        };
                    })
                    console.log(this.detailInfo);
                    this.addInit(this.detailInfo.adNo,boxType)
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },
        getDetail(id,boxType){
            this.boxType=boxType
            detail(id).then(res=>{
                console.log(res);
                if(res.data.code==200){
                    for(var key in this.addform){
                        this.addform[key]=res.data.data[key]
                    }
                    for(var key in res.data.data){
                        this.$set(this.detailInfo,key,res.data.data[key])
                    }
                    this.addGoodsList=res.data.data.itemList
                    console.log(this.detailInfo);
                    this.addInit(this.detailInfo.adNo,boxType)
                    if(boxType=='detail') this.operationLog(id,'adjust')
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },
        selectGoods(val,row){
            let arr = this.selectedList.map(item=>item.productId)
            if(!arr.includes(row.productId)){
                this.selectedList.push({
                    ...row,
                    adjustGoodType:'add',
                    adjustBadType:'add',
                    adjustGood:0,
                    adjustBad:0,
                    leftGoodQuantity:row.goodQuantity,
                    leftBadQuantity:row.badQuantity,
                })
            }else{
                this.selectedList.splice(arr.indexOf(row.id),1)
            }
            // this.selectedList=val.map(item=>{
            //     return {
            //         ...item,
            //         productId:item.id,
            //         adjustGoodType:'add',
            //         adjustBadType:'add',
            //         adjustGood:0,
            //         adjustBad:0
            //     };
            // })
        },
        selectAll(val){
            console.log(val);
            const arr = this.selectedList.map(item=>item.productId)
            if(val.length!=0){
                val.forEach(item=>{
                    if(!arr.includes(item.productId)){
                        this.selectedList.push({
                            ...item,
                            adjustGoodType:'add',
                            adjustBadType:'add',
                            adjustGood:0,
                            adjustBad:0
                        })
                    }
                })
            }else{
                const arr = this.goodsList.map(item=>item.productId)
                arr.forEach(item=>{
                    this.selectedList.forEach((i,index)=>{
                        if(i.productId==item.productId) this.selectedList.splice(index,1)
                    })
                })
            }
        },
        warehouseAdjustNote(status){
            this.$refs.addform.validate(valid=>{
                if(!valid) return
                if(this.addGoodsList.length==0) return this.$message.error('请选择商品')
                if(this.boxType=='add'){
                    const arr = this.addGoodsList.map(item=>{
                        return{
                            badQuantity:item.adjustBadType=='add'?item.adjustBad:-item.adjustBad,
                            goodQuantity:item.adjustGoodType=='add'?item.adjustGood:-item.adjustGood,
                            productId:item.productId,
                        }
                    })
                    this.dialogLoading=true
                    warehouseAdjustNote({...this.addform,operateType:status,productList:arr}).then(res=>{
                        this.dialogLoading=false
                        if(res.data.code==200){
                            this.$message.success(res.data.message)
                            this.addOrderBoxClose()
                            this.getListPage()
                        }else {
                            this.$message.error(res.data.message)
                        }
                    })
                }else if(this.boxType=='update') {
                    const arr = this.addGoodsList.map(item=>{
                        return{
                            badQuantity:item.adjustBadType=='add'?item.adjustBad:-item.adjustBad,
                            goodQuantity:item.adjustGoodType=='add'?item.adjustGood:-item.adjustGood,
                            productId:item.productId,
                            sku:item.commoditySku
                        }
                    })
                    console.log(arr);
                    this.dialogLoading=true
                    update({
                        id:this.detailInfo.id,
                        productList:arr,
                        tips:this.addform.tips,
                        operateType:status
                    }).then(res=>{
                        if(res.data.code==200){
                            this.$message.success(res.data.message)
                            this.addOrderBoxClose()
                            this.getListPage()
                        }else {
                            this.$message.error(res.data.message)
                        }
                    })
                }
            })
            
        },
        addOrderBoxClose(){
            this.$refs.addform.resetFields()
            this.addform={
                storageId:'',
                tips:'',
                type:''
            }
            this.detailInfo={}
            this.addOderBox=false
            this.addGoodsList=[]
            this.boxType=''
            this.AddBoxActiveName='first'
        },
        addProduct(){
            const arr = this.addGoodsList.map(item=>item.productId)
            this.selectedList.forEach(item=>{
                if(!arr.includes(item.productId)){
                    this.addGoodsList.push(item)
                }
            })
            this.selectGoodsBox=false
            this.goodsListInfo.keyword=''
            console.log(this.addGoodsList);
        },
        goodsSizeChange(val){
            this.goodsListInfo.pageSize=val
            this.getProductListPage()
        },
        goodsCurrentChange(val){
            this.goodsListInfo.current=val
            this.getProductListPage()
        },
        pushProduct(){
            this.$refs.addform.validate(valid=>{
                if(!valid) return
                this.selectedList=this.addGoodsList.map(item=>item)

                this.getProductListPage()
            })
        },
        getProductListPage(info){
            if(info=='find') this.goodsListInfo.current=1
            this.$refs.addform.validate(valid=>{
                if(!valid) return
                this.dialogLoading=true
                getProductListPage({
                    current:this.goodsListInfo.current,
                    pageSize:this.goodsListInfo.pageSize,
                    keyword:this.goodsListInfo.keyword,
                    storageId:this.addform.storageId,
                    isFilterZero:0,
                }).then(res=>{
                    console.log(res);
                    this.selectGoodsBox=true
                    this.dialogLoading=false
                    this.goodsList = res.data.values
                    this.goodsListInfo.total=res.data.pageInfo.total
                    const arr = this.selectedList.map(item=>{
                        return item.productId
                    })
                    this.goodsList.forEach((item,index)=>{
                        if(arr.includes(item.productId)){
                            this.$nextTick(()=>{
                                this.$refs.goodsTable.toggleRowSelection(this.goodsList[index]);
                            })
                        }
                    })
                })
            })
        },
        addInit(title,boxType){
            this.title=title
            this.boxType=boxType
            this.loading=true
            addInit().then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.addOderBox=true
                }else {
                    this.$message.error(res.data.message)
                }
            })
        },
        getPageInit(){
            pageInit().then(res=>{
                this.adjustSearchTimeTypeList=res.data.data.adjustSearchTimeTypeList
                this.adjustSearchTypeList=res.data.data.adjustSearchTypeList
                this.adjustStatusList=res.data.data.adjustStatusList
                this.adjustTypeList=res.data.data.adjustTypeList
                this.storageList=res.data.data.storageList
            })
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getListPage()
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.getListPage()
        },
        find(){
            this.pageInfo.current=1
            this.getListPage()
        },
        getListPage(){
            this.loading=true
            listPage({...this.pageInfo,status:this.activeName=='all'?'':this.activeName}).then(res=>{
                this.loading=false
                this.tableData=res.data.values
                this.total=res.data.pageInfo.total
            })
        }
    }
}
</script>

<style scoped lang='scss'>
.Box{
    width: 100%;
    height: calc(100% - 68px);
    display: flex;
    flex-direction: column;
    .content{
        flex: 1;
        overflow: auto;
        padding-top: 10px;
    }
}
::v-deep .el-input-group__prepend {
    background-color: #fff;
}
::v-deep .el-input-group__append {
    background-color: #fff;
}
::v-deep .el-tabs__content{
    padding: 0;
}

::v-deep .outTable .cell .el-table__expand-icon{
    display: none;
}
::v-deep .outTable .el-table__row--level-1{
    .cell{
        >.el-checkbox{
            display: none;
        }
    }
}
::v-deep .outTable .el-table__row--level-0{
    background:#EBEEF5;
}
.tableMenu{
    display: flex;
    align-items: center;
    >div{
        margin-right:30px;
        span:nth-of-type(1){
            color:#999999;
            font-size: 12px;
        }
    }
}
::v-deep .outTable .cell .el-table__indent{
    display: none;
}
::v-deep .outTable .cell .el-table__placeholder{
    display: none;
}
.status{
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    height: 15px;
    line-height: 15px;
    // padding: 0;
}
.oneline{
    white-space: nowrap;  
    text-overflow:ellipsis;
    overflow:hidden;
}
.orange{
    color: orange !important;
    border-color: orange;
}
.red{
    color: red !important;
    border-color: red ;
}
.green{
    color: #91D876 !important;
    border-color: #91D876;
}
</style>